<template>
    <p v-if="content.type === 'paragraph'" v-html="content.text"></p>
    <h2 v-else-if="content.type === 'heading'">{{ content.text }}</h2>
    <div v-else-if="content.type === 'image'" class="shareable-post-image">
        <p class="attachment-link-container shareable-image-block" :data-href="content.attachment.attachmentUrl">
            <img v-lazy="content.attachment.attachmentUrl"
                 :width="content.attachment.attachmentWidth" :height="content.attachment.attachmentHeight">
            <span class="image-share-box">
                <a href="javascript:void(0);"
                   class="fb-share social-button" onclick=" var description = ''; if ($('meta[property=\'og:description\']').length > 0) { description = $('meta[property=\'og:description\']').attr('content'); } window.open('https://www.facebook.com/dialog/feed?display=popup&amp;\
app_id='+BoredPanda.Config.fbAppId+'\
&amp;link='+encodeURIComponent('https://www.boredpanda.com/studio-ghibli-interior-real-life-householdquotes/?media_id=STILL_The-Secret-World-of-Arrietty-Bedroom2-60ca9343038f4-jpeg__880')+'\
&amp;picture='+encodeURIComponent('https://static.boredpanda.com/blog/wp-content/uploads/2021/06/STILL_The-Secret-World-of-Arrietty-Bedroom2-60ca9343038f4-jpeg__880.jpg') +'\
&amp;name='+encodeURIComponent('These Designers Show How Studio Ghibli Interiors Would Look Like In Real Life')+'\
&amp;description='+encodeURIComponent(description)+'\
&amp;redirect_uri=' + encodeURIComponent(BoredPanda.Config.pandaBaseUrl+'/close.html'), 'facebook-share-dialog', 'width=626,height=436'); BoredPanda.Tracking.trackImageShares('Facebook', '3050686', '/2021/06/STILL_The-Secret-World-of-Arrietty-Bedroom2-60ca9343038f4-jpeg__880.jpg', ''); return false;"><svg
                    class="svg-icon icon-facebook-share" role="img" height="46" width="46" viewBox="0 0 46 46"><path d="M43.5,0H2.5C1.1,0,0,1.1,0,2.5v40.9C0,44.9,1.1,46,2.5,46h22V28.2h-6v-6.9h6v-5.1c0-5.9,3.6-9.2,8.9-9.2
c2.5,0,4.7,0.2,5.4,0.3v6.2l-3.7,0c-2.9,0-3.4,1.4-3.4,3.4v4.4h6.9l-0.9,6.9h-6V46h11.7c1.4,0,2.5-1.1,2.5-2.5V2.5
C46,1.1,44.9,0,43.5,0z"></path></svg> </a><a href="javascript:void(0);" class="twitter-share social-button"
                                             onclick=" window.open( 'https://twitter.com/share?url='+encodeURIComponent('https://www.boredpanda.com/studio-ghibli-interior-real-life-householdquotes/?media_id=STILL_The-Secret-World-of-Arrietty-Bedroom2-60ca9343038f4-jpeg__880')+ '&amp;text='+encodeURIComponent('These Designers Show How Studio Ghibli Interiors Would Look Like In Real Life')+ '&amp;via=boredpanda', 'twitter-share-dialog', 'width=540,height=600'); BoredPanda.Tracking.trackImageShares('Twitter', '3050686', '/2021/06/STILL_The-Secret-World-of-Arrietty-Bedroom2-60ca9343038f4-jpeg__880.jpg', ''); return false;"><svg
                class="svg-icon icon-twitter-share" role="img" height="38" width="46" viewBox="0 0 46 38"><path d="M41.4,9.5c0,0.1,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1
C42,25.4,30.2,38,14.8,38C9.5,38,4.4,36.4,0,33.7c6.4,1,11.6-2.2,13.1-4c-4.3,0-8.2-3.7-8.5-6.6c0.9,0.1,2.8,0.3,4-0.3
c-5.8-1.6-7.4-6.1-7.3-9.4c1,1,3,1.2,4.1,1.1C2.2,12.5-0.3,6.9,2.6,1.8c4.7,6.2,13,9.8,20.1,10h0c-0.2-0.7-0.2-1.4-0.2-2.2
c0-5.3,4.3-9.6,9.5-9.6c2.7,0,5.1,1.1,6.9,3c0,0,0,0,0,0c1.5,0,4.6-1.3,5.9-2.1c-0.5,2-2.6,4.5-4,5.2C42.9,5.9,45.2,5,46,4.6
C44.7,6.8,42.8,8.5,41.4,9.5z"></path></svg> </a><a href="javascript:void(0);" class="pinterest-share social-button"
                                                   onclick="window.open( 'https://www.pinterest.com/pin/create/button/?url='+encodeURIComponent('https://www.boredpanda.com/studio-ghibli-interior-real-life-householdquotes/?media_id=STILL_The-Secret-World-of-Arrietty-Bedroom2-60ca9343038f4-jpeg__880') + '&amp;media='+encodeURIComponent('https://static.boredpanda.com/blog/wp-content/uploads/2021/06/STILL_The-Secret-World-of-Arrietty-Bedroom2-60ca9343038f4-jpeg__880.jpg') + '&amp;description='+encodeURIComponent('These Designers Show How Studio Ghibli Interiors Would Look Like In Real Life'), 'pintrest-pin-dialog', 'width=750,height=328'); BoredPanda.Tracking.trackImageShares('Pinterest', '3050686', '/2021/06/STILL_The-Secret-World-of-Arrietty-Bedroom2-60ca9343038f4-jpeg__880.jpg', ''); return false;"><svg
                class="svg-icon icon-pinterest-share" role="img" height="26" width="26" viewBox="0 0 26 26"><path d="M13,0C5.8,0,0,5.8,0,13c0,5.5,3.4,10.2,8.3,12.1c-0.1-1-0.2-2.6,0-3.7c0.2-1,1.5-6.5,1.5-6.5S9.5,14.1,9.5,13
c0-1.8,1-3.2,2.4-3.2c1.1,0,1.6,0.8,1.6,1.8c0,1.1-0.7,2.8-1.1,4.3c-0.3,1.3,0.6,2.3,1.9,2.3c2.3,0,4.1-2.4,4.1-6
c0-3.1-2.2-5.3-5.4-5.3c-3.7,0-5.9,2.8-5.9,5.6c0,1.1,0.4,2.3,1,3c0.1,0.1,0.1,0.2,0.1,0.4c-0.1,0.4-0.3,1.3-0.4,1.5
c-0.1,0.2-0.2,0.3-0.4,0.2c-1.6-0.8-2.6-3.1-2.6-5c0-4.1,3-7.9,8.6-7.9c4.5,0,8,3.2,8,7.5c0,4.5-2.8,8.1-6.8,8.1
c-1.3,0-2.6-0.7-3-1.5c0,0-0.7,2.5-0.8,3.1c-0.3,1.1-1.1,2.5-1.6,3.4c1.2,0.4,2.5,0.6,3.9,0.6c7.2,0,13-5.8,13-13
C26,5.8,20.2,0,13,0z"></path></svg> </a><a
                href="whatsapp://send?text=https://www.boredpanda.com/studio-ghibli-interior-real-life-householdquotes/?media_id=STILL_The-Secret-World-of-Arrietty-Bedroom2-60ca9343038f4-jpeg__880"
                data-action="share/whatsapp/share" class="whatsapp-share social-button"
                onclick=" BoredPanda.Tracking.trackImageShares('Whatsapp', '3050686', '/2021/06/STILL_The-Secret-World-of-Arrietty-Bedroom2-60ca9343038f4-jpeg__880.jpg', '');"> <svg
                class="svg-icon icon-whatsapp" role="img" height="46" width="46" viewBox="0 0 46 46"><g><g><path d="M39.3,6.7C35,2.4,29.2,0,23.1,0C10.5,0,0.2,10.2,0.2,22.8c0,4,1.1,7.9,3.1,11.4
L0,46l12.1-3.2c3.3,1.8,7.1,2.8,10.9,2.8h0c0,0,0,0,0,0C35.7,45.6,46,35.4,46,22.8C46,16.7,43.6,11,39.3,6.7z M23.1,41.8
L23.1,41.8c-3.4,0-6.8-0.9-9.7-2.6l-0.7-0.4l-7.2,1.9l1.9-7L7,32.9c-1.9-3-2.9-6.5-2.9-10.1c0-10.4,8.5-18.9,19-18.9
c5.1,0,9.9,2,13.5,5.6c3.6,3.6,5.6,8.3,5.6,13.4C42.1,33.3,33.6,41.8,23.1,41.8z M33.5,27.6c-0.6-0.3-3.4-1.7-3.9-1.9
c-0.5-0.2-0.9-0.3-1.3,0.3c-0.4,0.6-1.5,1.9-1.8,2.2c-0.3,0.4-0.7,0.4-1.2,0.1c-0.6-0.3-2.4-0.9-4.6-2.8c-1.7-1.5-2.9-3.4-3.2-3.9
c-0.3-0.6,0-0.9,0.3-1.2c0.3-0.3,0.6-0.7,0.9-1c0.3-0.3,0.4-0.6,0.6-1c0.2-0.4,0.1-0.7,0-1c-0.1-0.3-1.3-3.1-1.8-4.2
c-0.5-1.1-0.9-1-1.3-1c-0.3,0-0.7,0-1.1,0c-0.4,0-1,0.1-1.5,0.7s-2,1.9-2,4.8c0,2.8,2.1,5.5,2.3,5.9c0.3,0.4,4,6.1,9.8,8.6
c1.4,0.6,2.4,0.9,3.3,1.2c1.4,0.4,2.6,0.4,3.6,0.2c1.1-0.2,3.4-1.4,3.9-2.7c0.5-1.3,0.5-2.5,0.3-2.7C34.5,28,34.1,27.9,33.5,27.6z
"></path></g></g></svg> </a><a href="javascript:void(0);" class="link-share social-button"
                               data-link="https://www.boredpanda.com/studio-ghibli-interior-real-life-householdquotes/?media_id=STILL_The-Secret-World-of-Arrietty-Bedroom2-60ca9343038f4-jpeg__880"
                               onclick=" BoredPanda.Tracking.trackImageShares('Link', '3050686', '/2021/06/STILL_The-Secret-World-of-Arrietty-Bedroom2-60ca9343038f4-jpeg__880.jpg', ''); return false;"><svg
                class="svg-icon icon-link-share" role="img" height="482.135" width="482.136"
                viewBox="0 0 482.136 482.135"><g><path
                d="M455.482,198.184L326.829,326.832c-35.535,35.54-93.108,35.54-128.646,0l-42.881-42.886l42.881-42.876l42.884,42.876
c11.845,11.822,31.064,11.846,42.886,0l128.644-128.643c11.816-11.831,11.816-31.066,0-42.9l-42.881-42.881
c-11.822-11.814-31.064-11.814-42.887,0l-45.928,45.936c-21.292-12.531-45.491-17.905-69.449-16.291l72.501-72.526
c35.535-35.521,93.136-35.521,128.644,0l42.886,42.881C491.018,105.045,491.018,162.663,455.482,198.184z M201.206,366.698
l-45.903,45.9c-11.845,11.846-31.064,11.817-42.881,0l-42.884-42.881c-11.845-11.821-11.845-31.041,0-42.886l128.646-128.648
c11.819-11.814,31.069-11.814,42.884,0l42.886,42.886l42.876-42.886l-42.876-42.881c-35.54-35.521-93.113-35.521-128.65,0
L26.655,283.946c-35.538,35.545-35.538,93.146,0,128.652l42.883,42.882c35.51,35.54,93.11,35.54,128.646,0l72.496-72.499
C246.724,384.578,222.588,379.197,201.206,366.698z"></path></g></svg> </a></span><span
            class="image-shares-share-button single-post-shares"><svg class="svg-icon icon-shares-stats" role="img"
                                                                      height="24" width="23.9" viewBox="0 0 23.9 24"><path
            d="M19.8,8.2c-1.2,0-2.2-0.5-3-1.2l-8.5,4.3c0,0.2,0.1,0.3,0.1,0.5
c0,0.4-0.1,0.7-0.1,1l8.6,4.3c0.8-0.8,1.8-1.2,3-1.2c2.3,0,4.2,1.8,4.2,4.1c0,2.3-1.9,4.1-4.2,4.1c-2.3,0-4.2-1.8-4.2-4.1
c0-0.1,0-0.3,0-0.4l-9-4.5c-0.7,0.5-1.5,0.8-2.5,0.8C1.9,15.8,0,14,0,11.7c0-2.3,1.9-4.1,4.2-4.1c1.1,0,2.1,0.4,2.9,1.1l8.6-4.3
c0-0.1,0-0.2,0-0.4c0-2.3,1.9-4.1,4.2-4.1c2.3,0,4.2,1.8,4.2,4.1C23.9,6.4,22.1,8.2,19.8,8.2z"></path></svg></span></p>
        <div class="email-share-modal link-share-modal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body clearfix">
                        <div class="success">
                            <div class="newsletter"><input type="text" class="link" name="link"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
export default {
    name: 'post-content',
    props: {
        content: Object,
    }
}
</script>

<style scoped lang="scss">
.post-content {
    p {
        position: relative;
        line-height: 1.5;
        clear: none;
        float: none;
        word-wrap: break-word;
        -ms-word-break: break-word;
        word-break: break-word;
        overflow-wrap: break-word;
        padding: 0 0 12px 0;
        color: rgba(0, 0, 0, .8);
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        width: 100%;
        max-width: 100%;
        margin: 0;
        @media(max-width: 768px) {
            padding-left: 25px;
            padding-right: 25px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }
        @media(max-width: 480px) {
            font-size: 16px;
            line-height: 26px;
            padding-bottom: 20px;
            padding-left: 12px;
            padding-right: 12px;
        }
    }

    h2 {
        clear: both;
        font-family: Merriweather, serif;
        color: #000;
        font-size: 1.8em;
        line-height: 100%;
        padding: .5em 0 .3em 0;
        display: block;
        word-wrap: break-word;
        -ms-word-break: break-word;
        word-break: break-word;
        overflow-wrap: break-word;
        background: 0 0;
        font-weight: 700;
        margin: 0;
        @media (min-width: 769px) {
            font-size: 24px;
            padding: 5px 0 10px 0;
            line-height: 28px;
        }
        @media(max-width: 768px) {
            padding-left: 25px;
            padding-right: 25px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }
        @media(max-width: 480px) {
            padding-left: 12px;
            padding-right: 12px;
            text-align: left;
            font-size: 23px;
        }
    }

    img {
        width: 100%;
        max-width: 100%;
        display: block;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;
    }

    .shareable-post-image {
        position: relative;

        .image-shares-share-button {
            display: none;
            line-height: 25px;
            z-index: 1;
            width: 25px;
            height: 25px;
            background: rgba(0, 0, 0, 0.5);
            text-align: center;
            color: #ffffff;
            border-radius: 3px;
            position: absolute;
            bottom: 5px;
            right: 5px;

            .svg-icon {
                width: 13px;
                height: 13px;
                vertical-align: top;
                margin-top: 6px;
            }

            @media(max-width: 979px) {
                display: block;
            }
            @media(max-width: 768px) {
                &.single-post-shares {
                    margin-right: 25px;
                }
            }
            @media(max-width: 480px) {
                &.single-post-shares {
                    margin-right: 0;
                }
            }
        }

        .attachment-link-container .image-shares-share-button {
            margin-bottom: 0.75em;
        }
    }

    .attachment-link-container, .bp-video-container, .single-image-container {
        padding-right: 0;
        padding-left: 0;
    }

    .shareable-post-image {
        &:hover {
            @media(min-width: 980px) {
                .image-share-box {
                    display: block;
                }
            }
        }

        .shareable-image-block {
            position: relative;
            display: block;

            &.no-margin {
                padding-bottom: 0;
            }
        }
    }

    .image-share-box {
        z-index: 2;
        width: 60px;
        height: auto;
        display: none;
        position: absolute;
        top: 0;
        left: -60px;
        padding: inherit;
        box-sizing: border-box;

        .social-button {
            color: #ffffff;
            display: block;
            min-height: 43px;
            text-align: center;
            padding-top: 17px;

            &.fb-share {
                background-color: #3b5998;

                .svg-icon {
                    width: 26px;
                    height: 26px;
                }

                &:hover {
                    background-color: #4e6bba;
                }
            }

            &.pinterest-share {
                background-color: #bd081c;

                .svg-icon {
                    width: 26px;
                    height: 26px;
                }

                &:hover {
                    background-color: #cf1c2f;
                }
            }

            &.twitter-share {
                background-color: #6aadd1;

                .svg-icon {
                    width: 26px;
                    height: 26px;
                }

                &:hover {
                    background-color: #7abfe4;
                }
            }

            &.tumblr-share {
                background-color: #36475d;
                padding-top: 18px;
                min-height: 42px;

                .svg-icon {
                    width: 14px;
                    height: 24px;
                }

                &:hover {
                    background-color: #45586e;
                }
            }

            &.link-share {
                background-color: #353535;

                .svg-icon {
                    width: 24px;
                    height: 24px;
                }

                &:hover {
                    background-color: #4a4a4a;
                }
            }

            &.copy-link {
                background-color: #888;
                padding-bottom: 14px;
                font-size: 15px;
                font-weight: bold;
                line-height: 18px;

                &:hover {
                    background-color: #999;
                }
            }

            &.email-share-mobile {
                display: none;
                background-color: #353535;
            }

            &.email-share {
                background-color: #353535;

                .svg-icon {
                    width: 23px;
                    height: 17px;
                }

                &:hover {
                    background-color: #4a4a4a;
                }
            }

            &.whatsapp-share {
                display: none;
                background-color: #2ed269;
            }

            &.pinterest-share {
                background-color: #bd081c;

                .svg-icon {
                    width: 26px;
                    height: 26px;
                }

                &:hover {
                    background-color: #cf1c2f;
                }
            }

            &.pinterest-share {
                background-color: #bd081c;

                .svg-icon {
                    width: 26px;
                    height: 26px;
                }

                &:hover {
                    background-color: #cf1c2f;
                }
            }

            .svg-icon {
                vertical-align: middle;
            }
        }

        .statistics {
            width: 60px;
            display: block;
            line-height: 1;
            font-size: 12px;
            font-weight: 600;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    @media(max-width: 979px) {
        .image-share-box {
            bottom: 0;
            top: inherit;
            left: 0;
            width: 100%;
            padding-bottom: inherit;

            .social-button {
                display: none;
                height: 44px;
                line-height: 44px;
                width: 25%;
                padding-top: 0;

                &.tumblr-share {
                    display: none;
                }

                &.email-share {
                    display: none;
                }

                &.whatsapp-share {
                    display: inline-block;

                    .svg-icon {
                        width: 23px;
                        height: 23px;
                    }
                }

                &.twitter-share {
                    display: inline-block;

                    .svg-icon {
                        width: 23px;
                        height: 23px;
                    }
                }

                &.email-share-mobile {
                    display: inline-block;

                    .svg-icon {
                        width: 23px;
                        height: 17px;
                    }
                }

                &.fb-share {
                    display: inline-block;

                    .svg-icon {
                        width: 23px;
                        height: 23px;
                    }
                }

                &.pinterest-share {
                    display: inline-block;

                    .svg-icon {
                        width: 23px;
                        height: 23px;
                    }
                }
            }

            .statistics {
                display: none;
            }
        }
    }

    .email-share-modal {
        position: absolute;
        display: none;
        z-index: 999;

        .modal-dialog {
            margin: 0;
            height: 100%;

            .modal-content {
                height: auto;
                width: 520px;
                margin-left: 10px;
                border-radius: 3px;

                &:after {
                    border-right: 6px solid white;
                    margin-left: 4px;
                    z-index: 1;
                    content: "";
                    position: absolute;
                    border-top: 6px solid transparent;
                    border-bottom: 6px solid transparent;
                    top: 188px;
                    left: -10px;
                }

                border: none;
                -webkit-box-shadow: 0 5px 8px rgba(0, 0, 0, .1);
                box-shadow: 0 5px 8px rgba(0, 0, 0, .1);
                border: 1px solid #eeeeee;

                .modal-head {
                    .modal-close {
                        z-index: 1;
                        color: #eeeeee;
                        position: absolute;
                        right: 13px;
                        top: 13px;

                        .svg-icon {
                            width: 13px;
                            height: 13px;
                        }
                    }
                }

                .modal-body {
                    padding: 30px;
                    height: 100%;
                    box-sizing: border-box;
                    font-family: Roboto, sans-serif;

                    .success, .success-subscribe {
                        display: block;

                        .title {
                            padding-bottom: 30px;

                            i {
                                color: white;
                                background: #56b863;
                                border-radius: 50%;
                                padding: 8px;
                                width: 12px;
                                height: 12px;
                                display: inline-block;
                                vertical-align: middle;
                                margin-right: 11px;

                                .svg-icon {
                                    width: 12px;
                                    height: 12px;
                                    vertical-align: top;
                                }
                            }

                            h1 {
                                font-size: 18px;
                                color: #56b863;
                                font-weight: 500;
                                display: inline-block;
                                vertical-align: middle;
                                margin-left: 10px;
                            }
                        }

                        input {
                            font-family: Roboto, sans-serif;
                            display: block;
                            font-size: 16px;
                            line-height: 20px;
                            color: #353535;
                            width: 100%;
                            padding: 15px;
                            box-sizing: border-box;
                            border: 1px solid #d9d9d9;
                            border-radius: 3px;
                            margin-top: 20px;
                            outline: none;
                        }

                        .error {
                            font-family: Roboto, sans-serif;
                            display: block;
                            margin-top: 10px;

                            i {
                                background: #e44949;
                                height: 14px;
                                width: 2px;
                                color: #ffffff;
                                display: inline-block;
                                padding: 7px 13px;
                                border-radius: 50%;
                                vertical-align: middle;

                                .svg-icon {
                                    vertical-align: top;
                                }
                            }

                            .error-text {
                                font-family: Roboto, sans-serif;
                                display: inline-block;
                                vertical-align: middle;
                                font-weight: 400;
                                margin-left: 9px;
                                font-size: 14px;
                                color: #e44949;
                            }
                        }

                        .newsletter {
                            .newsletter-info {
                                margin-top: 21px;

                                .text {
                                    font-size: 16px;
                                    line-height: 20px;
                                    color: #353535;
                                }

                                .logo {
                                    background: url('/images/newsletter-panda.png') no-repeat;
                                    background-size: 60px 60px;
                                    width: 60px;
                                    height: 60px;
                                    display: inline-block;
                                    vertical-align: middle;
                                    @media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
                                        background: url('/images/newsletter-panda@2x.png') no-repeat;
                                        background-size: 60px 60px;
                                    }
                                    @media(-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi) {
                                        background: url('/images/newsletter-panda@3x.png');
                                        background-size: 60px 60px;
                                    }
                                }

                                .info {
                                    display: inline-block;
                                    font-size: 18px;
                                    color: #353535;
                                    vertical-align: middle;
                                    margin-left: 7px;

                                    .readers {
                                        display: block;
                                        font-size: 14px;
                                        color: #a0a0a0;
                                        font-weight: 400;
                                        padding-top: 0;
                                    }
                                }
                            }
                        }

                        .buttons {
                            margin-top: 20px;
                            float: right;

                            .modal-close {
                                color: #a0a0a0;
                                font-size: 14px;
                                margin-right: 16px;
                                display: inline-block;
                            }

                            .sign-up {
                                background: #56b863;
                                color: #ffffff;
                                font-size: 16px;
                                font-weight: bold;
                                padding: 12px 22px;
                                border-radius: 5px;
                                display: inline-block;

                                &:hover {
                                    background: #61c56f;
                                }
                            }
                        }
                    }

                    .success-subscribe {
                        display: none;

                        .title {
                            padding: 0;

                            i {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }

        &.subscribed {
            .modal-dialog {
                .modal-content {
                    width: auto;

                    &:after {
                        top: 30px;
                    }

                    .modal-body {
                        padding: 20px;
                    }

                    .modal-close {
                        display: none;
                    }
                }
            }
        }

        &.subscribe-form {
            .modal-dialog {
                .modal-content {
                    &:after {
                        top: 150px;
                    }
                }
            }
        }

        &.link-share-modal {
            .modal-dialog {
                .modal-content {
                    &:after {
                        top: 25px;
                    }

                    .modal-body {
                        padding: 15px;

                        .success {
                            input {
                                padding: 5px 10px;
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
